/* eslint-disable func-names */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import throttle from "lodash/throttle"

export default function ExitIntent(options = {}) {
  const defaultOptions = {
    threshold: 20,
    maxDisplays: 1,
    eventThrottle: 200,
    onExitIntent: () => {
      // function here
    },
  }

  return (function () {
    const config = { ...defaultOptions, ...options }
    const eventListeners = new Map()
    let displays = 0

    const addEvent = (eventName: string, callback: any) => {
      document.addEventListener(eventName, callback, false)
      eventListeners.set(`document:${eventName}`, { eventName, callback })
    }

    const removeEvent = (key: any) => {
      const { eventName, callback } = eventListeners.get(key)
      document.removeEventListener(eventName, callback)
      eventListeners.delete(key)
    }

    const shouldDisplay = (position: number) => {
      if (position <= config.threshold && displays < config.maxDisplays) {
        // eslint-disable-next-line no-plusplus
        displays++
        return true
      }
      return false
    }

    const mouseDidMove = (event: { clientY: any }) => {
      if (shouldDisplay(event.clientY)) {
        config.onExitIntent()
        if (displays >= config.maxDisplays) {
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          removeEvents()
        }
      }
    }

    const removeEvents = () => {
      eventListeners.forEach((value, key, map) => removeEvent(key))
    }

    addEvent("mousemove", throttle(mouseDidMove, config.eventThrottle))

    return removeEvents
  })()
}
