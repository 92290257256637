import React, { useEffect } from "react"

import "./StickyFooter.scss"
// import getApkLinkForUtmParam from '../../lib/get-apk-from-utm-param'
import DownloadButton from "../DownloadButton"

type Color = "black" | "white"

interface Props {
  bg: Color
}

const StickyFooter: React.FC<Props> = ({ bg }) => {
  const color: Color = bg === "black" ? "white" : "black"
  const boxShadowColor =
    bg === "black" ? "rgba(255, 255, 255, 0.5)" : "rgba(0,0,0,0.3)"

  useEffect(() => {
    const footer = document.getElementById("footer-mobile")
    if (footer) {
      footer.style.paddingBottom = "56px"
    }

    return () => {
      if (footer) {
        footer.style.paddingBottom = "0"
      }
    }
  })

  return (
    <div className="d-sm-none" id="sticky-btn-container">
      <div
        className="sticky-footer"
        style={{
          // background: bg,
          color,
          boxShadow: `0 0 2px 0 ${boxShadowColor}`,
        }}
      >
        <div className="rating">
          <div>4.7</div>
          <div>Rating</div>
        </div>
        <DownloadButton
          styles={{
            background: color,
            color: bg,
          }}
        />
        <div className="downloads">
          <div>50,00,000+</div>
          <div>Downloads</div>
        </div>
      </div>
    </div>
  )
}

export default StickyFooter
