import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import React, { useEffect, useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import { LiteYouTubeEmbed } from "react-lite-youtube-embed"
import Sms from "../Sms/Sms"
import "./GetMegaCarromPartyYoutube.scss"
import Popup from "../Popup/Popup"
import { GENERIC_APK } from "../download-links"
import ExitIntent from "../ExitIntent"
import DownloadButton from "../DownloadButton"
import { isMobile } from "../../formatForBigQuery"

const SubTitle = () => (
  <div className="subtitle">
    Share your GetMega Handle on their YouTube live chat!
  </div>
)

const GetMegaCarromPartyYoutube: React.FC = () => {
  const data = useStaticQuery(graphql`
    query {
      yt: file(relativePath: { eq: "CarromPartyYoutube.png" }) {
        childImageSharp {
          fluid(maxWidth: 460) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      logo: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      android: file(relativePath: { eq: "icon-android.png" }) {
        childImageSharp {
          fixed(width: 12, height: 14) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      ios: file(relativePath: { eq: "icon-ios.png" }) {
        childImageSharp {
          fixed(width: 11, height: 14) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  const [isPopupOpen, setIsPopupOpen] = useState(false)
  const [popupCookie, setPopupCookie] = useState(false)

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (document.cookie.indexOf("modal_seen=true") < 0) {
      setPopupCookie(true)
    }
    if (isMobile()) {
      // mobile
      const key = "popupDisplayed"
      const t = setTimeout(() => {
        try {
          const displayedInSession = JSON.parse(
            sessionStorage.getItem(key) || "false"
          )

          if (!displayedInSession) {
            setIsPopupOpen(true)
            sessionStorage.setItem(key, "true")
          }
        } catch (err) {
          console.error(err)
        }
      }, 25 * 1000)

      return () => {
        clearTimeout(t)
      }
    }
    // else
    const removeExitIntent = ExitIntent({
      threshold: 0,
      eventThrottle: 100,
      onExitIntent: () => {
        if (popupCookie) {
          setIsPopupOpen(true)
          // setting a cookie for 1 day
          const expiryDate = new Date(Date.now() + 1 * (1000 * 60 * 60 * 24))
          document.cookie = `modal_seen=true; expires=${expiryDate.toUTCString()}`
        }
      },
    })
    return () => {
      removeExitIntent()
    }
  }, [popupCookie])

  return (
    <div id="GetMegaCarromPartyYoutube">
      <div className="mobile d-block d-lg-none">
        <div>
          <Img fluid={data.yt.childImageSharp.fluid} alt="youtune poster" />
        </div>
        <div className="title">Go Live with your favourite YouTuber!</div>
        <SubTitle />
        <div className="yt-cta">
          <DownloadButton
            buttonText="Download App Now"
            entryPoint="top CTA button"
          />
          <div className="yt-cta-texts">
            <ul>
              <li>
                <div className="iconAndroid">
                  <Img
                    fixed={data.android.childImageSharp.fixed}
                    alt="android"
                  />
                </div>
                <span>4.7 Rating</span>
              </li>
              <li>
                <span>130K+ Downloads</span>
              </li>
              <li>
                <div className="iconIos">
                  <Img fixed={data.ios.childImageSharp.fixed} alt="ios" />
                </div>
                <span>Coming Soon</span>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="desktop d-none d-lg-block">
        <Container>
          <Row>
            <Col lg={6}>
              <Sms
                title={
                  <>
                    Go Live with your
                    <br />
                    favourite YouTuber!
                  </>
                }
                description={<SubTitle />}
              />
            </Col>
            <Col>
              <div className="video">
                <Img
                  loading="eager"
                  fluid={data.yt.childImageSharp.fluid}
                  alt=""
                  imgStyle={{ objectFit: "contain" }}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="promo-video">
        <Container>
          <LiteYouTubeEmbed id="-uWnsdGUiFE" params="rel=0&showinfo=0" />
        </Container>
      </div>
      {isPopupOpen ? (
        <Popup
          close={() => {
            setIsPopupOpen(false)
          }}
          apkLink={GENERIC_APK}
        />
      ) : null}
    </div>
  )
}

export default GetMegaCarromPartyYoutube
