import React, { useState } from "react"
import axios from "axios"

import "./SendSms.scss"
import { formatForBigQuery, isMobile } from "../../formatForBigQuery"

const SendSms: React.FC = () => {
  const [error, setError] = useState("")
  const [mobile, setMobile] = useState("")
  const [sending, setSending] = useState(false)
  const [successM, setSuccessM] = useState("")

  const phoneNumberRegex = /^\d{10}$/
  const validateNumber = (n: string): boolean => {
    if (n === "") {
      setError("Mobile number is required!")
      return false
    }
    if (!phoneNumberRegex.test(n)) {
      setError("Invalid mobile number!")
      return false
    }

    return true
  }

  const sendSms = async () => {
    if (sending) return

    setSuccessM("")
    if (!validateNumber(mobile)) return
    setError("")

    try {
      ;(window as any).analytics?.track("SMSAppLinkButtonClicked", {
        event_params: formatForBigQuery({
          device: isMobile() ? "Mobile" : "Desktop",
          mobile_number: mobile,
        }),
      })
    } catch (er) {
      console.error(er)
    }

    setSending(true)
    axios
      .post(
        `${process.env.GATSBY_MAPI_URL}/twirp/pb.Website/SendDownloadLinkSms`,
        {
          mobile_number: mobile,
        }
      )
      .then(() => {
        setSuccessM("SMS sent!")
        setSending(false)
        setMobile("")
      })
      .catch(() => {
        setError("Error sending sms! Too many requests.")
        setSending(false)
      })
  }

  return (
    <div className="send-sms">
      <div>
        <input
          type="tel"
          name="mobile"
          placeholder="Enter your mobile number"
          value={mobile}
          onChange={e => {
            const val = e.target.value
            const regex = /^\d+$/

            if (regex.test(val)) {
              setMobile(val)
            }

            if (phoneNumberRegex.test(val)) {
              try {
                ;(window as any).analytics?.track("PhoneNumberEntered", {
                  event_params: formatForBigQuery({
                    device: isMobile() ? "Mobile" : "Desktop",
                    phone_number: val,
                    parent_screen: window.location.href,
                  }),
                })
              } catch (er) {
                console.error(er)
              }
            }

            if (!val) setMobile("")
          }}
          className={error && "invalid"}
        />

        {(error || successM) && (
          <div
            className={`message ${error && "error"} ${successM && "success"}`}
          >
            {error || successM}
          </div>
        )}
      </div>
      <button type="button" onClick={sendSms}>
        SMS APP LINK
      </button>
    </div>
  )
}

export default SendSms
