import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import "./DownloadsAndRating.scss"
import RatingAndDownloads from "./RatingAndDownloads"

const DownloadsAndRating: React.FC = () => {
  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "getmega-logo-square-white.png" }) {
        childImageSharp {
          fluid(maxWidth: 120) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)

  return (
    <div className="download-and-rating">
      <div>
        <Img
          loading="eager"
          fluid={data.logo.childImageSharp.fluid}
          alt="getmega logo"
          imgStyle={{ objectFit: "contain" }}
        />
      </div>
      <div className="info">
        <div className="c">GetMega</div>
        <RatingAndDownloads color="#fff" />
      </div>
    </div>
  )
}

export default DownloadsAndRating
