import React, { useState } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"
import loadable from "@loadable/component"
import { Col, Container, Row } from "react-bootstrap"

import "./Header.scss"
import Breadcrumbs, { Breadcrumb } from "../Breadcrumbs/Breadcrumbs"
import { formatForBigQuery, isMobile } from "../../formatForBigQuery"

const DesktopLinks = loadable(() => import("./DesktopLinks"))
const Sidebar = loadable(() => import("./Sidebar/Sidebar"))

interface Props {
  background?: string
  showBreadcrumbs?: boolean
  breadcrumbs?: Breadcrumb[]
  desktopBreadcrumbColor?: string
}

const Header: React.FC<Props> = ({
  background = "#000",
  showBreadcrumbs = false,
  breadcrumbs = [],
  desktopBreadcrumbColor = "white",
}) => {
  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      menu: file(relativePath: { eq: "menu-24.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      menuBlack: file(relativePath: { eq: "menu-black.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const [sidebarOpen, setSidebarOpen] = useState(false)
  const logoClass = showBreadcrumbs ? "d-none d-sm-block" : ""

  return (
    <>
      <div className="header-container">
        <header id="header">
          <Container>
            <a
              href="https://www.getmega.com/"
              className={logoClass}
              onClick={() => {
                try {
                  ;(window as any).analytics?.track("homeButtonClicked", {
                    event_params: formatForBigQuery({
                      device: isMobile() ? "Mobile" : "Desktop",
                    }),
                  })
                } catch (er) {
                  console.error(er)
                }
              }}
            >
              <Img
                loading="eager"
                fluid={data.logo.childImageSharp.fluid}
                alt="Logo"
                className="getmega-logo"
              />
            </a>

            {showBreadcrumbs ? (
              <div className="d-block d-sm-none">
                <Breadcrumbs
                  breadcrumbs={breadcrumbs}
                  color={background === "#000" ? "white" : "black"}
                />
              </div>
            ) : null}

            <DesktopLinks />

            <button
              className="image-button"
              type="button"
              onClick={(): void => {
                setSidebarOpen(true)

                try {
                  ;(window as any).analytics?.track("hamburgerIconClicked", {
                    event_params: formatForBigQuery({
                      device: isMobile() ? "Mobile" : "Desktop",
                    }),
                  })
                } catch (er) {
                  console.error(er)
                }
              }}
            >
              <Img
                loading="eager"
                fluid={
                  data[background === "#fff" ? "menuBlack" : "menu"]
                    .childImageSharp.fluid
                }
                alt="Menu"
                className="menu-icon"
              />
            </button>
          </Container>
        </header>

        {showBreadcrumbs && breadcrumbs ? (
          <div className="desktop-breadcrumbs d-none d-sm-block">
            <Container>
              <Row>
                <Col>
                  <Breadcrumbs
                    breadcrumbs={breadcrumbs}
                    color={desktopBreadcrumbColor}
                  />
                </Col>
              </Row>
            </Container>
          </div>
        ) : null}
      </div>
      <Sidebar
        show={sidebarOpen}
        closeSidebar={(): void => {
          setSidebarOpen(false)
        }}
      />
    </>
  )
}

export default Header
