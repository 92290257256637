import React from "react"
import "./Breadcrumbs.scss"

export interface Breadcrumb {
  title: string
  url: string
}

interface Props {
  breadcrumbs: Breadcrumb[]
  color?: string
}

const Breadcrumbs: React.FC<Props> = ({ breadcrumbs, color = "#fff" }) => {
  return (
    <div className="breadcrumbs">
      {breadcrumbs.map((b, i) => {
        return (
          <React.Fragment key={`${b.title}-${b.url}`}>
            {i !== breadcrumbs.length - 1 ? (
              <a href={b.url} className="text" style={{ color }}>
                {b.title}
              </a>
            ) : (
              <span className="text" style={{ color }}>
                {b.title}
              </span>
            )}
            {i !== breadcrumbs.length - 1 && (
              <span className="text" style={{ color }}>
                {` / `}
              </span>
            )}
          </React.Fragment>
        )
      })}
    </div>
  )
}

export default Breadcrumbs
