import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import "./RatingAndDownloads.scss"

interface Props {
  color: string
}

const RatingAndDownloads: React.FC<Props> = ({ color }) => {
  const data = useStaticQuery(graphql`
    query {
      star: file(relativePath: { eq: "star-48.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <div className="rating" style={{ color }}>
      <div>
        <Img
          loading="eager"
          fluid={data.star.childImageSharp.fluid}
          alt="star"
          className="rating-star"
        />
        <span>4.7 Rating</span>
      </div>
      <div className="rating-separator">•</div>
      <div>50,00,000+ Downloads</div>
    </div>
  )
}

export default RatingAndDownloads
