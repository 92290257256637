import React from "react"
import DownloadsAndRating from "./DownloadsAndRating"
import SendSms from "./SendSms"
import "./Sms.scss"

interface Props {
  title?: React.ReactNode
  description?: React.ReactNode
}

const Sms: React.FC<Props> = ({ title, description }) => {
  return (
    <div id="Sms">
      <h1 className="title" style={{ color: "#fff" }}>
        {title ? (
          <>{title}</>
        ) : (
          <>
            World’s Largest Social
            <br />
            Real Money Gaming Platform
          </>
        )}
      </h1>
      {description ? <>{description}</> : null}
      <h3>Get Android app link through SMS</h3>
      <SendSms />
      <div style={{ marginTop: "40px" }}>
        <DownloadsAndRating />
      </div>
    </div>
  )
}

export default Sms
