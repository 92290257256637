import React, { useEffect, useState } from "react"
import QueryString from "query-string"
import Onelink from "../onelink"
import { formatForBigQuery, isMobile } from "../formatForBigQuery"

interface Props {
  apkLink?: string
  styles?: any
  buttonText?: string
  entryPoint?: string
}

const DownloadButton: React.FC<Props> = ({
  styles,
  buttonText,
  entryPoint,
}) => {
  const [apk, setApk] = useState(Onelink)
  useEffect(() => {
    const parsed = QueryString.parse(window.location.search)
    setApk(`${apk}&c=${parsed.name || "Organic"}`)
  }, [])

  return (
    <>
      <a
        href={apk}
        style={styles}
        onClick={() => {
          try {
            // eslint-disable-next-line prettier/prettier
            ;(window as any).analytics?.track("downloadButtonClicked", {
              event_params: formatForBigQuery({
                device: isMobile() ? "Mobile" : "Desktop",
                entry_point: entryPoint || "Sticky download button",
              }),
            })
          } catch (er) {
            console.error(er)
          }
        }}
      >
        {buttonText || "Download Now"}
      </a>
    </>
  )
}

export default DownloadButton
