import React, { useEffect } from "react"
import "bootstrap/dist/css/bootstrap.min.css"
import GetMegaCarromPartyYoutube from "../components/GetMegaCarromPartyYoutube/GetMegaCarromPartyYoutube"
import SEO from "../components/seo"
import { formatForBigQuery, isMobile } from "../formatForBigQuery"
import Header from "../components/Header/Header"
import StickyFooter from "../components/StickyFooter/StickyFooter"

const GetMegaCarromPartyYoutubeContainer: React.FC = () => {
  useEffect(() => {
    try {
      ;(window as any).analytics?.track("CarromPartyYoutubePageViewed", {
        event_params: formatForBigQuery({
          device: isMobile() ? "Mobile" : "Desktop",
        }),
      })
    } catch (er) {
      console.error(er)
    }
  }, [])

  return (
    <div>
      <SEO title="GetMegaCarromParty" description="GetMegaCarromParty" />
      <Header />
      <GetMegaCarromPartyYoutube />
      <StickyFooter bg="black" />
    </div>
  )
}

export default GetMegaCarromPartyYoutubeContainer
