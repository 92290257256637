import React, { useCallback, useEffect, useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

import "./Popup.scss"
import useInterval from "../../hooks/useInterval"
import { getApkLink } from "../../lib/get-apk-from-utm-param"
import { GENERIC_APK } from "../download-links"
import SendSms from "../Sms/SendSms"

interface Props {
  close(): void
  apkLink: string
}

const Popup: React.FC<Props> = ({ close }) => {
  const data = useStaticQuery(graphql`
    query {
      close: file(relativePath: { eq: "close-menu.png" }) {
        childImageSharp {
          fixed(width: 12) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      image1: file(
        relativePath: { eq: "free-gems-popup/free-gems-popup.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 760) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      image2: file(
        relativePath: { eq: "free-gems-popup/free-gems-popup.png" }
      ) {
        childImageSharp {
          fixed(width: 190) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `)

  const [dCount, setDCount] = useState(3598)
  useInterval(() => {
    setDCount(d => d + 1)
  }, 10 * 1000)

  const [apk, setApk] = useState(GENERIC_APK)
  const updateApk = useCallback(async () => {
    setApk(await getApkLink())
  }, [])
  useEffect(() => {
    updateApk()
  }, [updateApk])

  return (
    <div id="free-gems-backdrop">
      <div className="bg">
        <div id="free-gems-popup">
          <div className="close">
            <button type="button" onClick={close}>
              <Img
                fixed={data.close.childImageSharp.fixed}
                alt="close button"
              />
            </button>
          </div>

          <div className="mobile">
            <div style={{ width: "190px" }} className="mobile-mascot">
              <Img fixed={data.image2.childImageSharp.fixed} alt="getmega" />
            </div>

            <span className="heading h_cust">Why miss amazing games?</span>
            <span className="subtext">
              Have tonnes of fun with Poker, Carrom, Rummy, Trivia! Win real
              cash with <strong>your skills</strong>.
            </span>

            <div>
              <a href={apk} className="mobile-link">
                Install Now
              </a>
            </div>
            <span className="downloads">
              Today&apos;s Downloads : <span className="b">{dCount}</span>
            </span>
          </div>

          <div className="desktop">
            <div className="content">
              <span className="heading">Why miss amazing games?</span>
              <span className="text">
                Have tonnes of fun with Poker, Carrom, Rummy, Trivia! Win real
                cash with <strong>your skills</strong>.
              </span>
              <span className="sms-text">Get download link through SMS</span>
              <SendSms />
              <span className="downloads">
                Today&apos;s downloads : <span className="b">{dCount}</span>
              </span>
            </div>

            <div className="img">
              <Img
                fluid={data.image1.childImageSharp.fluid}
                alt="getmega"
                className="desktop-mascot-image"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Popup
